import React from "react";
import "/src/assets/scss/components/text-block.scss";

function TextBlock(props) {
  const { title, text } = props;
  const paragraphs = text.map((paragraph, index) =>
    <p key={index}>
      {paragraph}
    </p>
  );
  return (
    <section className="text-block">
      <div className="container">
        <div className="text-block-row">
          <h2 className="title-2">{title}</h2>
          <div className="text-block-copy">{paragraphs}</div>
        </div>
      </div>
    </section>
  );
}

export default TextBlock;
