import React from "react";
import { Link } from "gatsby";
import "/src/assets/scss/components/cards/card-detailed.scss";

function CardDetailed(props) {
  const { title, imageSrc, imageAlt, description } = props;
  return (
    <li className="card-detailed-col">
      <div className="card-detailed-image-col">
        <img src={imageSrc} alt={imageAlt} className="card-detailed-image" />
      </div>
      <div className="card-detailed-text-col">
        <h3 className="card-detailed-title">{title}</h3>
        <div className="card-detailed-copy">{description}</div>
        <Link to="/contact" className="btn">
          REQUEST A QUOTE
        </Link>
      </div>
    </li>
  );
}

export default CardDetailed;
