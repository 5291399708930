import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/structure/Layout";
import HeroProducts from "../components/heros/HeroProducts";
import TextBlock from "../components/TextBlock";
import imgMahiMahi from "../static/images/products/img-mahi-mahi-500x325.png";
import imgSwordFish from "../static/images/products/img-swordfish-500x325.png";
import imgTunaFish from "../static/images/products/img-tuna-500x325.png";
import imgCorvinaFish from "../static/images/products/img-corvina-500x325.png";
import imgEscolarFish from "../static/images/products/img-escolar-500x325.png";
import imgGroupersFish from "../static/images/products/img-groupers-500x325.png";
import imgAtlanticSnapperFish from "../static/images/products/img-atlantic-snapper-500x325.png";
import imgPacificSnapperFish from "../static/images/products/img-pacific-snapper-500x325.png";
import imgTilapiaFish from "../static/images/products/img-tilapia-500x325.png";
import imgTripletailFish from "../static/images/products/img-tripletail-500x325.png";
import imgWahooFish from "../static/images/products/img-wahoo-500x325.png";
import imgCobiaFish from "../static/images/products/img-cobia-500x325.png";
import imgFlukeFish from "../static/images/products/img-fluke-500x325.png";
import imgPompanoFish from "../static/images/products/img-pompano-500x325.png";
import imgStoneCrab from "../static/images/products/img-stone-crab-500x325.png";
import imgStripedBassFish from "../static/images/products/img-striped-bass-500x325.png";
import CardDetailedList from "../components/cards/CardDetailedList";

function Products() {
  const textBlockTitle = "OUR PRODUCTS";
  const textBlockCopy = [
    "Fantastic Fish Company wants to be the go-to source for seafood distributors, national and regional retailers, supermarkets, local and chain restaurant groups; and here is why:",
    "Our wild fish are harvested throughout many different fisheries throughout Latin America, South America, the Caribbean and domestically. Our suppliers operate under approved United States FDA HACCP Plans and maintain the standards of sanitation in order to assure our customers of safe products. Their fishermen target specific species via traditional hook and line. This method insures the highest quality catch, with the least amount of by-catch possible, which makes our fish sustainable.",
    "Our farm raised fish are produced with the least possible amount of environmental impact to the specific ecosystem. Our tilapia farm is spring-fed and 100% of the water and waste is utilized or re-introduced to the ecosystem with minimal impact to the environment.",
    "Depending on the season, our core products include the following species:",
    <span className="footnote">
      Note: H&G is a term used to describe fish that have had the heads and guts
      removed. Also, all sizes are labeled in lbs.
    </span>,
  ];
  const cards = [
    {
      cardTitle: [
        "Mahi-Mahi",
        <br />,
        <span className="card-detailed-subtitle">(Coryphaena hippurus)</span>,
      ],
      imageSrc: imgMahiMahi,
      imageAlt: "Mahi-Mahi Fish",
      cardDescription: [
        <p>
          <strong>Mahi-Mahi</strong> has translucent pinkish flesh and a bright
          red bloodline. They have a lean flesh with a mild, sweet flavor
          profile, moderately firm texture and large moist flakes.
        </p>,
        <p>
          H&G, Whole-gutted <br />
          Origin: Pacific Coast, Domestic
          <br />
          Sizes: 5-10, 10-15, 15-20, 20+
        </p>,
      ],
    },
    {
      cardTitle: [
        "Swordfish",
        <br />,
        <span className="card-detailed-subtitle">(Xiphias gladius)</span>,
      ],
      imageSrc: imgSwordFish,
      imageAlt: "Swordfish",
      cardDescription: [
        <p>
          <strong>Swordfish</strong> has a mildly sweet flavor and a moist meaty
          texture with moderately high fat content. The flesh can range from
          white or ivory to pink or orange. The color variations do not reflect
          quality. All Swordfish turn beige in color after cooking.
        </p>,
        <p>
          H&G
          <br />
          Origin: Pacific Coast, Domestic
          <br />
          Sizes: 5-10, 10-15, 15-20, 20+
        </p>,
      ],
    },
    {
      cardTitle: "Tuna",
      imageSrc: imgTunaFish,
      imageAlt: "Tuna Fish",
      cardDescription: [
        <p>
          Species available: Bigeye (Thunnus obesus), Yellowfin (Thunnus
          albacares)
        </p>,
        <p>
          <strong>Bigeye Tuna</strong> are prized for sashimi. They have a
          moderately pronounced flavor, a high fat content with marbling near
          the skin and a richer flavor than Yellowfin. Tuna Grading is as
          follows: No. 1 "Sashimi-grade" is the best, being the freshest and
          having the highest fat content. No. 2 "Grill-grade" is next best.
        </p>,
        <p>
          <strong>Yellowfin Tuna</strong> has a medium-mild flavor with very
          firm texture.
        </p>,
        <p>
          Origin: Atlantic, Central and South America
          <br />
          Sizes: 30-39, 40-59, 60+
        </p>,
      ],
    },
    {
      cardTitle: "Corvina",
      imageSrc: imgCorvinaFish,
      imageAlt: "Corvina Fish",
      cardDescription: [
        <p>Species available: Golden (Cynscion Acoupa ), Silver</p>,
        <p>
          <strong>Corvina</strong> has a mild, sweet taste with a firm, large
          flaked flesh, which is pinkish when raw but cooks up white. The flesh
          resembles Snapper. In South America Corvina is regarded as a prime
          table fish and is very popular for ceviche.
        </p>,
        <p>
          H&G, Whole-gutted
          <br />
          Origin: South and Central America
          <br />
          Sizes: 5-10, 10+
        </p>,
      ],
    },
    {
      cardTitle: [
        "Escolar",
        <br />,
        <span className="card-detailed-subtitle">
          (Lepidocybium flavobrunneum)
        </span>,
      ],
      imageSrc: imgEscolarFish,
      imageAlt: "Escolar Fish",
      cardDescription: [
        <p>
          <strong>Escolar</strong> meat is oil-rich and flavor-intensive. Raw
          meat is a bright white to a light cream color and cooked meat looks
          creamy white.
        </p>,
        <p>
          H&G
          <br />
          Origin: South and Central America
        </p>,
      ],
    },
    {
      cardTitle: "Groupers",
      imageSrc: imgGroupersFish,
      imageAlt: "Groupers Fish",
      cardDescription: [
        <p>
          Species available: Black (Mycteroperca bonaci), Broomtail
          (Mycteŕoperca zenarcha), Gag (Mycteroperca microlepis), Red
          (Epinephelus morio), Scamp (Mycteroperca phenax), Strawberry
        </p>,
        <p>
          <strong>Fresh Grouper</strong> is a lean, moist fish with a
          distinctive yet mild flavor, large flakes and a firm texture.
        </p>,
        <p>
          Whole-gutted/filet
          <br />
          Origin: Gulf of Mexico, Caribbean, Pacific Ocean
          <br />
          Sizes: 5-10, 10-20, 20-30, 30-40, 40+
        </p>,
      ],
    },
    {
      cardTitle: "Atlantic Snapper",
      imageSrc: imgAtlanticSnapperFish,
      imageAlt: "Atlantic Snapper Fish",
      cardDescription: [
        <p>
          Species available: American Red (Lutjanus campechanus), Atlantic Lane,
          Hog Fish (Lachnolaimus maximus), Mangrove (Lutjanus griseus), Mutton
          (Lutjanus analis), Yellowtail (Ocyurus chrysurus)
        </p>,
        <p>
          <strong>Snappers</strong> are lean, have a delicate texture and very
          fine white meat with mild flavor and subtle sweet undertones.
        </p>,
        <p>
          Whole-gutted
          <br />
          Sizes: ¾-1, 1-2, 1.5-2, 2-4, 4-6
        </p>,
      ],
    },
    {
      cardTitle: "Pacific Snapper",
      imageSrc: imgPacificSnapperFish,
      imageAlt: "Pacific Snapper Fish",
      cardDescription: [
        <p>
          Species available: Guacamayo, Pacific Lanes (Lutjanus synagris), Silk
          (Lutjanus vivanus), Yellow (Lutjanus argentiventris)
        </p>,
        <p>
          <strong>Snappers</strong> are lean, have a delicate texture and very
          fine white meat with mild flavor and subtle sweet undertones.
        </p>,
        <p>
          Whole-gutted
          <br />
          Sizes: ¾-1, 1-2, 1.5-2, 2-4, 4-6, 6+
        </p>,
      ],
    },
    {
      cardTitle: "Tilapia",
      imageSrc: imgTilapiaFish,
      imageAlt: "Tilapia Fish",
      cardDescription: [
        <p>
          <strong>Tilapia</strong> has a sweet, mild taste with lean flesh and a
          medium-firm, flaky texture. Raw flesh is white or pinkish-white
          sometimes with a darker muscle layer on the skin side of the fillets.
          The flesh cooks up to a white color. It’s been said that Aqua-cultured
          Tilapia tend to have a better flavor than wild Tilapia because Tilapia
          taste like their environment and wild fish feed on algae.
        </p>,
        <p>
          H&G, Whole-gutted
          <br />
          Origin: Nicaraguan Aquaculture.
          <br />
          Sizes: 3-5, 5-7, 7-9, 9-11, 11-13 oz fillets
        </p>,
      ],
    },
    {
      cardTitle: [
        "Tripletail",
        <br />,
        <span className="card-detailed-subtitle">
          (Lobotes Pacificus / Surinamensis)
        </span>,
      ],
      imageSrc: imgTripletailFish,
      imageAlt: "Tripletail Fish",
      cardDescription: [
        <p>
          Whole-gutted
          <br />
          Origin: Central and South America
          <br />
          Sizes: 3-5, 5-10, 10-20
        </p>,
      ],
    },
    {
      cardTitle: [
        "Wahoo",
        <br />,
        <span className="card-detailed-subtitle">
          (Acanthocybium solandri)
        </span>,
      ],
      imageSrc: imgWahooFish,
      imageAlt: "Wahoo Fish",
      cardDescription: [
        <p>
          <strong>Wahoo</strong> is also known as Ono. Ono has mild-sweet
          tasting fish with a firm texture, moderate fat, and large, circular
          flakes when cooked. The flesh is pale-pink and turns white when
          cooked.
        </p>,
        <p>H&G</p>,
      ],
    },
    {
      cardTitle: [
        "Cobia",
        <br />,
        <span className="card-detailed-subtitle">(Rachycentron canadum)</span>,
      ],
      imageSrc: imgCobiaFish,
      imageAlt: "Cobia Fish",
      cardDescription: [
        <p>
          <strong>Raw Cobia</strong> meat is a light tan color, when cooked it
          turns white. The meat is firm with a sweet, rich flavor with a nice
          flake. The oil content is similar to salmon, making the flesh moist.
        </p>,
        <p>
          H&G
          <br />
          Note: From time to time we also carry other native species of the
          Pacific, Atlantic, and Caribbean.
        </p>,
      ],
    },
    {
      cardTitle: [
        "Fluke",
        <br />,
        <span className="card-detailed-subtitle">(Paralichthys dentatus)</span>,
      ],
      imageSrc: imgFlukeFish,
      imageAlt: "Fluke Fish",
      cardDescription: [
        <p>
          <strong>Raw fluke</strong> looks tan to pinkish white, but cooked meat
          is pure white, lean, flaky with a mild flavor.
        </p>,
        <p>
          Origin: Domestic
          <br />
          Seasonal
        </p>,
      ],
    },
    {
      cardTitle: [
        "Pompano",
        <br />,
        <span className="card-detailed-subtitle">(Trachinotus carolinus)</span>,
      ],
      imageSrc: imgPompanoFish,
      imageAlt: "Pompano Fish",
      cardDescription: [
        <p>
          <strong>Pompano</strong> has a firm texture, light meat with mild
          flavor. Lean fish.
        </p>,
        <p>
          Whole-in the round
          <br />
          Origin: Gulf of Mexico
        </p>,
      ],
    },
    {
      cardTitle: "Stone Crab",
      imageSrc: imgStoneCrab,
      imageAlt: "Stone Crab",
      cardDescription: [
        <p>
          <strong>Stone Crab</strong> claw meat is sweet, mild, and firm.
        </p>,
        <p>
          Origin: Domestic
          <br />
          Sizes: Medium, Large, Jumbo, Colossal
        </p>,
      ],
    },
    {
      cardTitle: "Striped Bass",
      imageSrc: imgStripedBassFish,
      imageAlt: "Striped Bass Fish",
      cardDescription: [
        <p>
          <strong>Striped Bass</strong> have light-colored flesh with a delicate
          flavor and firm, large flakes.
        </p>,
        <p>
          Whole-gutted
          <br />
          Origin: Domestic
          <br />
          Seasonal
        </p>,
      ],
    },
  ];
  return (
    <div className="page-products">
      <Helmet>
        <html lang="en" />
        <title>Fantastic Fish Company | Products</title>
        <meta
          name="description"
          content="Fantastic Fish Company wants to be the go-to source for seafood
          distributors, national and regional retailers, supermarkets, local and
          chain restaurant groups."
        />
      </Helmet>
      <Layout>
        <HeroProducts />
        <main id="main-content" tabIndex="-1">
          <TextBlock title={textBlockTitle} text={textBlockCopy} />
          <CardDetailedList cards={cards} />
        </main>
      </Layout>
    </div>
  );
}

export default Products;
