import React from "react";
import "/src/assets/scss/components/cards/card-detailed-list.scss";
import CardDetailed from "./CardDetailed";

function CardDetailedList(props) {
  const { cards } = props;
  const listItems = cards.map(
    ({ cardTitle, imageSrc, imageAlt, cardDescription }, index) => (
      <CardDetailed
        title={cardTitle}
        imageSrc={imageSrc}
        imageAlt={imageAlt}
        description={cardDescription}
        key={index}
      />
    )
  );
  return (
    <div className="card-detailed-list-section">
      <div className="container">
        <ul className="card-detailed-list-row">{listItems}</ul>
      </div>
    </div>
  );
}

export default CardDetailedList;
